<template>
  <div>
    <h4>사용자 리스트</h4>
    <el-table :data="users">
      <el-table-column prop="email" label="이메일" />
      <el-table-column label="이름">
        <template #default="scope">
          {{ scope.row.firstName }} {{ scope.row.lastName }}
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="핸드폰번호" />
      <el-table-column label="선금잔액">
        <template #default="scope">
          $ {{ scope.row.advanceBalance }}
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="scope">
          <el-button size="mini" @click="$router.push({ name: 'users-edit', params: { id: scope.row.id }})">
            수정
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import UserService from "../services/UserService"

export default {
  name: "UserList",
  data() {
    return {
      users: [],
    }
  },
  methods: {
    async retrieveUsers() {
      try {
        const response = await UserService.getAll()
        this.users = response.data
        console.log(response.data)
      } catch (e) {
        console.log(e)
      }
    },
  },
  mounted() {
    this.retrieveUsers()
  }
}
</script>

<style>
</style>